/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import Box from '@mui/material/Box'; 
import { UseProContext } from "../context/productContext";
import { useState,useEffect } from 'react';
import FormatPrice from "../comp/FormatPrice";
import { Link} from "react-router-dom";

const Popup = () => {
  const values = UseProContext();
   const initial=[{ pro_id:'',cate_id:'',pro_title:'',offer:'',price:'',
   img:[{url:'https://www.digiinvite.in/ceo/assets/images/pro.png'}],m_price:'',code:''}];

   const [state, setState] = useState([]);
    useEffect(() => {
    if (values.featureProducts.length>1) { 
      setState(values.featureProducts[4].pro);
      // console.log(values.featureProducts);
    } 
    }, [values,state]);


   const [show, setShow] = useState(-1);
   const [showId, setShowId] = useState(0);
    useEffect(() => {
        const interval = setInterval(() => { 
          if(state.length>0){
            handelBest(showId);
          }
        }, 6000);
        return () => clearInterval(interval);
    }, [state,showId,show]);
 
    // console.log(state);

   const [pro, setPro] = useState(...initial);
    // console.log(pro.pro_id);
   function handelBest(id) {
    // const best = state.filter((pro,i)=>i===show); 
    setShowId(ids=>state.length-1>ids?ids+1:0);
    setShow(id);
    setTimeout(() => {
        setShow(-1);
    }, 5000);
    const best = state[id];
    setPro(best);
    
    // if(state.length){}

   }
    // console.log(state.length);
    // console.log(pro);
   

  return (
    < >
    {values.isLoading?'':
        <section className="wbpopup-popducts">
          <div className="popinner"
          style={show>-1?{display:"block",transform: 'translate(0%)',opacity: 1,}
          :{display:"none",transform: 'translate(-105%)',opacity: 0,}}>
            <div className="product-container">
                {state&&state.length>0?pro&&pro.pro_id?<><PopupPro pro={pro} /></>:<></>:<></>}
                {/* {<></>} */}
                
            </div> 
            <div className="wbclosepopup" onClick={()=>setShow(-1)}>
              <i className="fa fa-close"></i>
            </div>
          </div>
        </section>
    }
    </>
  );
};
 
const PopupPro = ({pro}) => {
  const { code, pro_title, img, price } = pro;
    // const offPrice = m_price / 1;, m_price,offer,pro_id
    const href=`/product/${code}`; 
    // const imgUrl=`http://ceo.digiinvite.in/images/pro.jpg`;
    // const imgUrl2="//cdn.shopify.com/s/files/1/0531/1604/3454/products/20_38fec71e-6560-4075-8682-e4934cc45c14_large.jpg?v=1611816036";
    //  <img  src={pro.img.length>0?`${pro.img[0].url}`:`${imgUrl}`} alt=" "  /> 
     
    const img1=img.length>0?`${img[0].url}`:`https://www.digiinvite.in/ceo/assets/images/pro.png`;
  const img2=img.length>1?`${img[1].url}`:img.length>0?`${img[0].url}`:`https://www.digiinvite.in/ceo/assets/images/pro.png`;

    return ( 
        <div className="row">
            <Box className="imgblock " sx={{width:{ xs: '33.33%', md: '33.33%' },}}>
                <Link to={href}>
                    <img src={img1} alt="alt" />
                </Link>
                <Link to={href}>
                    <img src={img2}  className="second-img "  alt="alt"  /> 
                </Link>
            </Box>
            <Box className="wbproductdes"sx={{width:{ xs: '66.67%', md: '66.67%' },overflow:'hidden'}}>
                <h5>
                    <Link to={href} className="grid-link__title" >
                    {pro_title} 
                    </Link>
                    <p className="thumbdes ">{code}</p>
                </h5>
                <div className="product_price">
                    <span className="grid-link__org_price money"> 
                    <FormatPrice price={price} /> 
                    {/* Rs. 12,652.82 */}
                    </span>
                </div>
            </Box>
        </div> 
    );
}
 


export default Popup;
