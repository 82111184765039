
import './style/font-awesome.min.css';
import './style/App.css';
import './style/animate.css';
import './style/slick-theme.css';
import './style/fonts.css';
import './style/timber.scss.css';
import './style/spr-071';
import './style/flags.min.css'
import "slick-carousel/slick/slick.css";

// import "slick-carousel/slick/slick-theme.css";

import React from 'react';
// import ReactDOM from "react-dom/client";
import { BrowserRouter , Routes, Route, Outlet} from "react-router-dom";
import  ResponsiveAppBar  from "./page/navbar.jsx";
import ScrollToTop from './comp/scrollTop';
import Home from './page/home';
import About from './page/about';
import Terms from './page/Terms';
import Privacy from './page/Privacy';
import FAQ from './page/faq';
import Blogs from './page/Blogs';
import Contact from './page/Contact';
// import Products from './page/Products';
import FilterCate from './page/filterCate';
import Product from './page/Product';
import NoPage from './page/NoPage';
import Search from './page/Search';
import Checkout from './page/Checkout';
import Carts from './page/Carts';
import Account from './page/account';
import Payment from './page/Payment';
import RouterScrollToTop from './comp/RouterScroll';
import Footer from './page/footer';
import Currency from './page/currency';
import LogsHandler,{ ForgotPswHandler,LogOutHandler } from './page/logsHandler';
//  import { UseProContext } from "./context/productContext";
 
// import ScrollToTop from './comp/scrollToTop';
import { styled} from '@mui/material/styles';
const StyledApp= styled('div')(({ theme }) => ({
  "& .MuiTypography-root":{fontFamily: 'Work Sans',},
 }));

function App() {
  // const values = UseProContext();
  // const banner=values.banners.banner&&values.banners.banner[7]?values.banners.banner[7].url:``;
  // console.log(banner);
  // const feature = values.featureProducts;values.banners?values.banners.banner[8].url:
  return (
    <StyledApp>
    <BrowserRouter  Basename={process.env.PUBLIC_URL} >
    <RouterScrollToTop>
      <Routes >
        <Route path="/" element={<Layout />}>
          <Route index element={<Home />} />
          <Route path="Forgot/:token" element={<ForgotPswHandler />} />
          <Route path="new-password" element={<ForgotPswHandler />} />
          <Route path="logout" element={<LogOutHandler />} />
          <Route path="Sign" element={<LogsHandler />} />
          <Route path="profile" element={<Account />} />
          <Route path="blogs" element={<Blogs />} />
          <Route path="about" element={<About />} />
          <Route path="faq" element={<FAQ />} />
          <Route path="currency" element={<Currency />} />
          <Route path="Privacy-Policy" element={<Privacy />} />
          <Route path="Terms-Conditions" element={<Terms />} />
          <Route path="contact" element={<Contact />} />
          <Route path="search/:item" element={<Search />} />
          <Route path="search/:item/:page" element={<Search />} />
          <Route path="carts" element={<Carts />} ignoreScrollBehavior />
          <Route path="checkout" element={<Checkout />} ignoreScrollBehavior />
          {/* <Route path="pro" element={<Products />} /> */}
          <Route path="payment" element={<Payment />} />
          <Route path="products/:cate" element={<FilterCate />} />
          <Route path="products/:cate/:sub_cate" element={<FilterCate />} />
          <Route path="product/:pro" element={<Product />} />
          <Route path="*" element={<NoPage />} />
        </Route>
      </Routes>

    </RouterScrollToTop>
    </BrowserRouter>
    <Outlet />
    {/* <Popup /> */}
    </StyledApp>
  );
}
const Layout = () => {
  return (
    <>
    <ResponsiveAppBar />
      <Outlet />
    <ScrollToTop />
    <Footer/>
    </>
  )
};
export default App;
