/* eslint-disable react-hooks/exhaustive-deps */

import * as React from 'react';
import { useState,useEffect } from 'react';
import { styled} from '@mui/material/styles';
import Box from '@mui/material/Box';
// import Popover from '@mui/material/Popover';
// import Drawer from '@mui/material/Drawer';
import AppBar from '@mui/material/AppBar';
import Container from '@mui/material/Container';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import MenuItem from '@mui/material/MenuItem';
// import InputLabel from '@mui/material/InputLabel'; 
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
// import Avatar from '@mui/material/Avatar';
import digiLogo from '../img/digiLogo.png';
import Category from './homes/category';
// import PageviewIcon from '@mui/icons-material/Pageview';
// import SettingsIcon from '@mui/icons-material/Settings';
import StarOutlineIcon from '@mui/icons-material/StarOutline';
import ShoppingCartCheckoutIcon from '@mui/icons-material/ShoppingCartCheckout';
// import PersonIcon from '@mui/icons-material/Person';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
// import Person2Icon from '@mui/icons-material/Person2';
import Cart from '../comp/Cart';
import Wishlist from '../comp/wishlist';
import { Link,useLocation} from "react-router-dom";
import SearchIcon from '@mui/icons-material/Search';
// import InputBase from '@mui/material/InputBase';
// import {data} from '../comp/data';
// import {pages} from '../comp/data';
// import {product} from '../comp/data';
import { UseProContext } from '../context/productContext';
import { UseCartContext } from '../context/CartContext'; 
// import { UseUserContext } from '../context/userContext '; 
const logOutAccounts = [
    // {name: 'Account',url: '/account',icon:'fa-user'},
    // {name: 'Profile',url: '/Profile',icon:'fa-lock'}, 
    // {name: 'blogs',url: '/blogs',icon:'fa-signal'},
    // {name: 'Dashboard',url: '/Dashboard',icon:'fa-signal'},
    // {name: 'Sign Up',url: '/Sign',icon:'fa-user'},
    {name: 'Sign In',url: '/Sign',icon:'fa-sign-in'}
  ];


  const accounts = [
    {name: 'Profile',url: '/profile',icon:'fa-user'},
    {name: 'Logout',url: '/logout',icon:'fa-sign-out'},
  ];


  const navLink = [
    {name: 'Home',url: '',icon:'fa-home'},
    {name: 'About Us',url: '/about',icon:'fa-user'},
    {name: 'Blogs',url: '/blogs',icon:'fa-pencil-square-o'},  
    {name: 'Contact',url: '/contact',icon:'fa-comment'},
    // {name: 'Policy',url: '/Policy'},  
  ];
const Buttons = styled(Button)(({ theme }) => ({
  color: 'black',//position: `fixed`, fontSize: '16px',
  '&:hover':{color:`#ffad19`, backgroundColor: 'transparent',}
}));

const NavStyle = styled(Box)(({ theme }) => ({
    backgroundColor: 'white',//position: `fixed`, fontSize: '16px',
      // "& .container":{ margin:'0 auto 0 auto',width:{xs:'100%',sm:'90%',md:'85%'},padding:'0 15px 0 15px',
      // },
      "& .shopCount":{background: '#ededed',borderRadius: '50%',position: 'absolute',
      fontSize: 'initial',fontWeight: 900,width: '20px',top: 0,left: '50%',},
      "& .MuiTypography-root":{fontFamily: 'Work Sans',
      '&:hover':{color:`#ffad19`}
     }, 
      "& .MuiSelect-select":{fontFamily: 'Work Sans',fontSize:'14px'},
      '&:hover, &.Mui-focusVisible': {backgroundColor:`transparent`, },
      '&.Mui-active': {color:`red`},
  }));

const ResponsiveAppBar = () =>{
    const {carts}=UseCartContext();
//   console.log(setCarts);
  const values=UseProContext(); 
  // const user=UseUserContext(); 
  // console.log(values); 

  const cartCount =carts.cart;
  const [shopCount, setShopCount] = useState(0);

   useEffect(() => {
    const Count =
    setShopCount(cartCount.length)
    // const shopCount=;
    return Count;
  }, [cartCount]);

  
  // let location = useLocation();
  // const pathname=location.pathname; 

//   const [state, setState] = React.useState({});
//   const [styleDrop2, setStyleDrop2] = React.useState({});
  const [anchorelNav, setAnchorelNav] = React.useState(null);
  const [anchorelCart, setAnchorelCart] = React.useState(null);
  const [anchorelWish, setAnchorelWish] = React.useState(null);
  const [anchorelUser, setAnchorelUser] = React.useState(null);

  const [showSuggest, setShowSuggest] = React.useState(false);
  const [suggestion, setSuggestions] = React.useState();
  // console.log(suggestion);
  // const [title, setTitle] = React.useState({});

  // useEffect(() => {
    // values.subcategory.map(sub=>{

    // })
    // const filtered = product.filter(pro => {
    //   const title = pro.pro_title.toLowerCase();
    //   const code = pro.code.toLowerCase();
    //   const tag = pro.pro_tag.toLowerCase();
    //   return title.includes(item.toLowerCase())
    //         ||code.includes(item.toLowerCase())
    //         ||tag.includes(item.toLowerCase());
    //   }
    // );
    // setSuggestions(values.subcategory);
  // }, [values]);

  const curr =[
    {id:0,name:'INR ₹',value:'flag-in',set:'en-IN,INR',},
    {id:1,name:'USD $',value:'flag-us',set:'en-US,USD',},
    // {id:2,name:'ERO',value:'',set:'de-DE,EUR',},
    // {id:3,name:'JPY',value:'',set:'ja-JP,JPY',},
  ]
  // const [state, setstate] = useState(0);
  let newCurr = localStorage.getItem('curr');
  // console.log(newCurr);
  // useEffect(() => { 
  // for (let i = 0; i < 4; i++) { 
  //   curr[i].set===newCurr? setstate(i) :'';
  // } 
  // }, [newCurr]);
  const [currency, setCurrency] = React.useState(newCurr?newCurr==='en-IN,INR'?0:1:0);
  // const handleCurrChange = (event) => {setCurrency(event.target.value); ChangeCurr(event.target.value)};
  const handleCurrChange = (e) => {setCurrency(e); ChangeCurr(e)};
  function ChangeCurr(value) {
    values.isLoading=true;
    localStorage.setItem('curr', curr[value].set); 
    values.isLoading=false;

    window.history.pushState({}, "", "/currency" );
    const navEvent = new PopStateEvent('popstate');
    window.dispatchEvent(navEvent); 
    setTimeout(() => {
      window.history.pushState({}, "", "/?currency="+curr[value].name);
      const navEvent2 = new PopStateEvent('popstate');
      window.dispatchEvent(navEvent2); 

    }, 1000);
  }

  // useEffect(() => {
  //     localStorage.setItem('curr', curr[currency].set);
  // }, [currency]);
  
  // eslint-disable-next-line react-hooks/exhaustive-deps

//   const [anchorelDrop, setAnchorelDrop] = React.useState(null);
//   const [dropdown2, setDropdown2] = React.useState(null);

  const handleOpenNavMenu = (event) => { setAnchorelNav(event.currentTarget);};
  const handleOpenCart = (event) => { setAnchorelCart(event.currentTarget);};
  const handleOpenWish = (event) => { setAnchorelWish(event.currentTarget);};
  const handleOpenUserMenu = (event) => {setAnchorelUser(event.currentTarget);};
//   const handleOpenDrop = (event) => {
//     setAnchorelDrop(event);setState({height: '0',visibility:'hidden',opacity:'0'});
//     setTimeout(() => setState({transition: 'opacity 0.5s,visibility 0s linear 0.1s',opacity: '1',visibility:'visible',}), 1);
//   }; 
//   const OpenDrop2 = (event) => {
//     setDropdown2(event);setStyleDrop2({height: '0',visibility:'hidden',opacity:'0'});
//     setTimeout(() => setStyleDrop2({transition: 'opacity 0.3s,visibility 0s linear 0.1s',opacity: '1',visibility:'visible',}), 1);
//   };
  const handleCloseNavMenu = () => {setAnchorelNav(null);};
  const handleCloseCart = () => {setAnchorelCart(null);};
  const handleCloseWish = () => {setAnchorelWish(null);};
  const handleCloseUserMenu = () => {setAnchorelUser(null); };
//   const handleCloseDrop = (e) => {setAnchorelDrop(null);setState({height: '0'});};
  const [scrollStyle, setScrollStyle] = useState({boxShadow: 'none',top:'-6rem',});
  const [position, setPosition] = useState('fixed');
  var prevScroll = window.pageYOffset;
  // console.log(prevScroll);
  window.onscroll = function() {
      var currentScrollPos = window.pageYOffset;
      if (document.body.scrollTop > 100 || document.documentElement.scrollTop > 100) {
          if (prevScroll > currentScrollPos) {setPosition('fixed'); 
          setScrollStyle({transition: 'top 0.3s',boxShadow: '0px -5px 10px grey',top:'0px',});
          }else{setScrollStyle({transition: 'top 0.3s',boxShadow: 'none',top:'-6rem',});}
      } else{setPosition('fixed');// if(currentScrollPos<80)
      setScrollStyle({ boxShadow: 'none', top:'-6rem',});}
      prevScroll = currentScrollPos;
  } 

  const [inputText, setInputText] = useState("");

  const location = useLocation();
  useEffect(() => {
    setScrollStyle({ boxShadow: 'none', top:'-6rem',});

    const locations = location.pathname.split('/');
    console.log(locations[1]);
    if(locations[1]===`search`){
      setInputText(locations[2].replace('%20', ' '));
    }else{setInputText('');}

  }, [location]);
    let inputHandler = (e) => {
      // console.log(e);
      var lowerCase = e.toLowerCase();
      setInputText(e);
      if (e==='') {
        // setSuggestions();
        setShowSuggest(false);
      }else{
        const filtered = values.proTeg.filter(teg => {
          const title = teg.toLowerCase(); 
          return title===lowerCase?false:title.includes(e.toLowerCase());
        }); 
        filtered.sort((a, b) => a.length - b.length);
        filtered.length=5;
        setSuggestions(filtered);
        setShowSuggest(true);
      } 
    };
    const onFormSubmit = e => {
      setShowSuggest(false);
      e.preventDefault();
      if(inputText!==''){
      window.history.pushState({}, "", "/search/"+inputText)
      }
      const navEvent = new PopStateEvent('popstate');
      window.dispatchEvent(navEvent);

    }
    
    // function handelSuggest(params) {
    //   inputHandler(params);
    //   setShowSuggest(false);
    //   if(inputText!==''){ 
    //     window.history.pushState({}, "", "/search/"+params)
    //     }
    //   const navEvent = new PopStateEvent('popstate');
    //   window.dispatchEvent(navEvent);
    // }


  return (
    <NavStyle>
      <Box component='header' sx={{bgcolor:'#f5f5f5',height:'2rem',display: { xs: 'none', md: 'flex' },}}>
        <Box sx={{display:'flex',justifyContent:'space-between',}} className='container'>
            <Typography sx={{lineHeight:'2rem',}} >Call or <a href="https://api.whatsapp.com/send?phone=+918858783587&text=Hello" target={`_blank`}> Whatsapp +91 88-587-83-587 </a> </Typography>
            <Box sx={{display:'flex',justifyContent:'space-between',ml:'auto',width:{xs:'50%',sm:'30%',md:'30%'}}}>
            <Box sx={{ display: 'relative' }}>
                {/* <Typography sx={{lineHeight:'2rem'}}>My Account vhjvhhj</Typography> */}
                <Box component='a' onClick={handleOpenUserMenu} > 
                    <Tooltip title="Open settings">
                    <Typography sx={{lineHeight:'2rem',cursor: 'pointer' }}> {localStorage.getItem("token-info") ==null? 'Account':'My Profile'} </Typography> 
                    </Tooltip>
                </Box>
            </Box>
            <hr style={{color: 'black',border: 0,width: '1px',background: 'black',}} />
            <Box> 
              <FormControl variant="standard" sx={{height: '2rem', minWidth: 120 }}>
                <Select value={currency}  onChange={(event)=>handleCurrChange(event.target.value)} sx={{height: '2rem',}} >
                  {curr.map(cur=>
                    <MenuItem value={cur.id} key={cur.id}  sx={{fontFamily:'Work Sans',fontSize:'14px'}}>
                      <i className={`flag  ${cur.value}`}></i>  {cur.name} 
                    </MenuItem>
                   )}
                </Select>
              </FormControl>
            </Box>
                  {/* <MenuItem value={10}flag-inflag-us  sx={{fontFamily:'Work Sans'}}> INR</MenuItem>
                  <MenuItem value={20} sx={{fontFamily:'Work Sans'}}> USD</MenuItem> */}
              {/* <Typography sx={{lineHeight:'2rem'}}>"filled","outlined","standard"<Box component='a' >INR</Box> </Typography> */}
            </Box>
        </Box>
    </Box>
          <Menu id="basic-menu" anchorEl={anchorelUser} 
              anchorOrigin={{  vertical: 'bottom', horizontal: 'center', }}
              transformOrigin={{ vertical: 'top', horizontal: 'center', }}
              open={Boolean(anchorelUser)} onClose={handleCloseUserMenu}
              MenuListProps={{'aria-labelledby': 'basic-button', }} >
              {navLink.map(nav=>
                <Link to={nav.url} key={nav.name} >
                  <MenuItem onClick={handleCloseUserMenu} sx={{display:{xs:'flex',md:'none',}}}>
                      <Typography  textAlign="center" sx={{fontFamily:'Work Sans'}} >
                        <i className={`fa  ${nav.icon} `}></i> {nav.name}</Typography>
                  </MenuItem>
                </Link>
              )}
              {localStorage.getItem("token-info") ==null?logOutAccounts && logOutAccounts.map((setting) => (
                <MenuItem key={setting.name} onClick={handleCloseUserMenu} sx={{display:{xs:'flex',md:'flex',}}}>
                  <Link to={setting.url}>
                    <Typography  textAlign="center" sx={{fontFamily:'Work Sans'}} >
                      <i className={`fa  ${setting.icon} `}></i> {setting.name}</Typography>
                  </Link>
                </MenuItem>
              )):accounts && accounts.map((setting) => (
                <MenuItem key={setting.name} onClick={handleCloseUserMenu} sx={{display:{xs:'flex',md:'flex',}}}>
                  <Link to={setting.url}>
                  <Typography  textAlign="center" sx={{fontFamily:'Work Sans'}} >
                    <i className={`fa  ${setting.icon} `}></i> {setting.name}</Typography>
                  </Link>
                </MenuItem>
              ))}
            <MenuItem sx={{display:{xs:'flex',md:'none',}}}> 
              <FormControl variant="standard" sx={{height: '2rem', minWidth: 120 }}>
                <Select value={currency}  onChange={(event)=>handleCurrChange(event.target.value)} sx={{height: '2rem',}} >
                  {curr.map(cur=>
                    <MenuItem value={cur.id} key={cur.id}  sx={{fontFamily:'Work Sans',fontSize:'14px'}}>
                      <i className={`flag  ${cur.value}`}></i> {cur.name}
                    </MenuItem>
                  )}
                </Select>
              </FormControl>
            </MenuItem>
          </Menu>


    <Container maxWidth="xl" sx={{zIndex:610,position:{xs:'none',sm:position,md:position},display:{xs:'none',sm:'block',md:'block'},bgcolor: 'white',}}style={scrollStyle}>
    <Toolbar disableGutters sx={{height: '5rem',zIndex:601,}} className='container'>
        <Link to='' >
            <Box sx={{  mr: 2,  display: { xs: 'none', sm: 'flex', md: 'flex' }, height: '5rem',}}>
                <img src={digiLogo} className="digi-logo" alt="logo desktop" /> 
            </Box>
        </Link>
        <Box  sx={{ml:'auto',width:'50%',display:'flex',justifyContent:'space-evenly',}} className="statmenu" >
            {navLink.map((page)=> 
                <Typography  textAlign="center" key={page.name} ><Link to={page.url}>{page.name}</Link></Typography>
            )}
        </Box>
        <Tooltip title="Shopping Cart">
              <IconButton sx={{display:{xs:'inline-flex',md:'inline-flex'}}} onClick={handleOpenCart} >
                <ShoppingCartCheckoutIcon /><span className='shopCount'>{shopCount>0?shopCount:''}</span>
              </IconButton>
        </Tooltip>
    </Toolbar>
    </Container>
    <AppBar position="static" sx={{boxShadow: 'none',bgcolor: 'white',}} >
        {/* <Container maxWidth="xl" sx={{zIndex:500,position:{xs:'relative',sm:'relative',md:'relative'},}} > */}
      <Toolbar disableGutters sx={{height: '5rem', }} className='container'>
          <Box sx={{ flexGrow: 1,height: '5rem', display: { xs: 'flex', md: 'none' } }}>
            <IconButton  size="large"  aria-label="account of current user"
              aria-controls="menu-appbar"  aria-haspopup="true"  onClick={handleOpenNavMenu}  
            > <MenuIcon />
            </IconButton>
            {/* <Menu id="basic-menu" className="site-nav" anchorEl={anchorelNav} 
                    anchorOrigin={{  vertical: 'bottom', horizontal: 'center', }}
                    transformOrigin={{ vertical: 'top', horizontal: 'center', }}
                    open={Boolean(anchorelNav)} onClose={handleCloseNavMenu}
                    // MenuListProps={{'aria-labelledby': 'basic-button', }}
                    >
              </Menu> */}
              <Box  className="site-nav"  sx={{zIndex:'1200',top:'100%',position:'absolute',
                  display:{xs: anchorelNav?'block':'none', md: 'none' }}} >
                    <Box sx={{position:'fixed',top:'0',bottom:0,left:0,right:0,zIndex:'1100',}}
                    onClick={handleCloseNavMenu}></Box>
                    <Box sx={{zIndex:'1200',position:'relative',background:'#fff',
                    // boxShadow: '0 0 5px #0000002d',
                     boxShadow: '0px 5px 5px -3px rgb(0 0 0 / 20%), 0px 8px 10px 1px rgb(0 0 0 / 14%), 0px 3px 14px 2px rgb(0 0 0 / 12%)',
                  }}>
                      <Category values={values}/>
                    </Box>
              </Box>

            {/* <Drawer  id="menu-appbar"  anchorel={anchorelNav}  keepMounted open={Boolean(anchorelNav)}
              onClose={handleCloseNavMenu} sx={{ display: { xs: 'block', md: 'none' }, }}>
              {pages && pages.map((page) => (
                <MenuItem key={page.id} > 
                  <a href={page.url}>
                    <Typography   className={page.url===pathname?'active':''} textAlign="center">{page.title} 1</Typography>
                  </a> 
                </MenuItem>
              ))}
              <MenuItem> <Link to={"products-cate/Religion/Punjabi-Invites"} > 
                <Typography textAlign="center">Home mobile</Typography></Link> 
              </MenuItem> 
            </Drawer> */}
          </Box>
          
          <Box   sx={{   mr: 0,height: '5rem',display: { xs: 'flex', md: 'none' }}}>
              <Link to='' ><img src={digiLogo} className="digi-logo" alt="Mobile logo" /></Link>
          </Box>


        <Box sx={{  mr: 0,  display: { xs: 'none', md: 'flex' }, height: '5rem',width:'20%' }}>
            <Link to='' ><img src={digiLogo} className="digi-logo" alt="logo desktop" /> </Link>
        </Box>
          
          <Box sx={{ m: 'auto',width: { xs: '10%', md: '50%' },height: '5rem', flexGrow: 0,display:'flex', flexDirection: 'row-reverse',alignItems:'center',  }}>
            
            <Box id="search" sx={{p:'0 1px 0 10%',width:{ xs: '90%', md:  '100%' },zIndex:100,left:'0',
            position:{ xs: 'absolute', md: 'relative' },display:'flex',top:{xs: '110%', md: '0'}}}>
                <form   onSubmit={onFormSubmit} className="search-header search">
                    <div id="search_block_top">
                        <input className="search_query search-header__input search__input"  
                            type="search" name="q" placeholder="Search..." aria-label="Search..." 
                            onChange={(e)=>inputHandler(e.target.value)}
                            onFocus={(e)=>setShowSuggest(true)}
                            onBlur={(e)=>setTimeout(()=>{setShowSuggest(false)},500)}
                            list="search_query_list" autoComplete="off" role="presentation"
                            // onBlur={(e)=>setShowSuggest(false)} 
                            value={inputText} />
                        <datalist id="search_query_list"  >
                            {suggestion&&suggestion.map((suggest,i)=><option key={i}  value={suggest}></option>)}
                        </datalist>
                        {showSuggest&&<></>}
                        <button type="submit" className="text-link top-header__link top-header__search-submit" onClick={onFormSubmit} >
                            <SearchIcon sx={{zIndex:'101',}}/>
                        </button>
                        {/* {showSuggest&& 
                          <ul className='suggestions'>
                            {suggestion&&suggestion.map((suggest,i)=><li key={i} className='items' onClick={(e)=>handelSuggest(suggest)} >{suggest}</li>)}
                          </ul>
                          } */}
                            {/* {suggestion&&suggestion.map((suggest,i)=>i<5?<li key={i} className='items' onClick={(e)=>handelSuggest(suggest.titles)}>{suggest.titles}</li>:'')} */}
                        {/* <div className='suggestion'></div> */}
                    </div>
                </form>
            </Box>
            
          </Box>
          <Box sx={{display:'flex',}} >
             <Box sx={{display: { xs: 'flex', md: 'none' },}}>
              <Tooltip title="Shopping Cart">
                <IconButton sx={{display:'inline-flex'}} onClick={handleOpenUserMenu} >
                  <PersonOutlineOutlinedIcon />
                </IconButton>
              </Tooltip>
             </Box>
            <Box sx={{display:{ xs: 'none', md: 'flex' }}}>
              <Tooltip title="Shopping Cart">
                <Buttons onClick={handleOpenWish} startIcon={<StarOutlineIcon />}>Wishlist</Buttons>
              </Tooltip>
            </Box>
            <Box >
             <Tooltip title="Shopping Cart">
              <IconButton sx={{display:{xs:'inline-flex',md:'inline-flex'}}} onClick={handleOpenCart} >
                <ShoppingCartCheckoutIcon /><span className='shopCount'>{shopCount>0?shopCount:''}</span>
              </IconButton>
             </Tooltip>
            </Box>
            <Menu id="menu-appbar" anchorEl={anchorelCart} 
                    anchorOrigin={{  vertical: 'bottom', horizontal: 'left', }}
                    transformOrigin={{ vertical: 'top', horizontal: 'center', }}
                    open={Boolean(anchorelCart)} onClose={handleCloseCart} >
              <Cart closeCart={handleCloseCart} />
            </Menu>
            <Menu id="menu-appbar" anchorEl={anchorelWish} 
                    anchorOrigin={{  vertical: 'bottom', horizontal: 'left', }}
                    transformOrigin={{ vertical: 'top', horizontal: 'center', }}
                    open={Boolean(anchorelWish)} onClose={handleCloseWish} >
              <Wishlist closeCart={handleCloseWish} />
            </Menu>  
          </Box>
      </Toolbar>

        <hr style={{margin: '0',}} /> 
        <Toolbar disableGutters sx={{ zIndex:'1',}} className='container'>
            <Box sx={{width: '100%',display:{ xs: 'none', md: 'flex' } }} className="row">
                <Box sx={{width:'50%',display:'flex'}}
                 className="  rightw statmenu hidden-xs">
                    {navLink.map((page)=> 
                        <Typography  textAlign="center" key={page.name} ><Link to={page.url}>{page.name}</Link></Typography>
                     )}
                </Box> 
                <Box sx={{width:'3%',mr:'auto',ml:'auto',}}></Box>
                <Box className="pull-right spg row" sx={{alignItems:'center'}} > 
                    <img src="https://www.digiinvite.in/ceo/assets/images/gift.svg" alt="ok" />
                    <Link to="/Gift"> Reseller Gift</Link> 
                </Box>
            </Box>
        </Toolbar>
        <hr style={{margin: '0',}} />

        {/* </Container>  */}
    </AppBar>



    </NavStyle>
  )
}
export default  ResponsiveAppBar;