import React,{useEffect}from "react";
import CountUp from "react-countup";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
//  import TvIcon from '@mui/icons-material/Tv';
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import DownloadForOfflineIcon from "@mui/icons-material/DownloadForOffline";
import SentimentSatisfiedAltIcon from "@mui/icons-material/SentimentSatisfiedAlt";
import VideoLibraryIcon from '@mui/icons-material/VideoLibrary';
// import CoffeeIcon from "@mui/icons-material/Coffee";
import Banner from "./homes/banner";
import Button from '@mui/material/Button';
import { Link } from "react-router-dom";
import Review from './homes/review2'; 
// import Helmet from "react-helmet";
import { UseProContext } from "../context/productContext";

// import YouTube from 'react-youtube';

const StyledAbout = styled(Box)(({ theme }) => ({
  // "& .MuiTypography-root": { fontFamily: "Work Sans" },
  //   "& .MuiTypography-root":{fontFamily: 'Work Sans',},
  //   "& .MuiTypography-root":{fontFamily: 'Work Sans',},
  // "& label":{width:'25%'},
  //   "& input,textarea":{width:'75%'},
}));
const DivHover = styled(Box)(({ theme }) => ({
  padding:'3.5%',background:'white',color:'#6c6d72',
  borderRadius:'12px',boxShadow:`1px 1.732px 60px 0 rgb(0 0 0 / 10%)`,
  position: 'relative',top: '0px', 
  transitionDuration: `0.5s`,
  border:`2px solid white`,
  "&:hover ":{ top: '-20px', color:'#ffad19',
    boxShadow:`1px 1.732px 60px 0 rgb(0 0 0 / 10%)`,
    border:`2px solid #ffad19`,
    "& a":{color:'blue', }
  },
 }));

 const StyledIcon = styled(Box)(({ theme }) => ({
  background:`linear-gradient(90deg,#ffad19 50%,transparent 50%),
  linear-gradient(90deg,#ffad19 50%,transparent 50%),
  linear-gradient(0deg,#ffad19 50%,transparent 50%),
  linear-gradient(0deg,#ffad19 50%,transparent 50%)`,
  backgroundPosition:`0 0,100% 100%,0 100%,100% 0`,
  backgroundRepeat:`repeat-x,repeat-x,repeat-y,repeat-y`,
  backgroundSize:`8px 3px,8px 3px,3px 8px,3px 8px`,
  backgroundRadius:`6px`,
  marginBottom:`10px`,
  position:`relative`,
  "& svg": {  fontSize: "4rem", color: "#6c6d72" },

  "&:hover ":{
    animation: 'dash 5s linear infinite',
    "& svg": { animation: 'shakes 1s', color: "#ffad19"},

  }
  // "& .MuiTypography-root": { fontFamily: "Work Sans" },
   
}));
//  background: linear-gradient(90deg,var(--primary) 50%,transparent 50%),linear-gradient(90deg,var(--primary) 50%,transparent 50%),linear-gradient(0deg,var(--primary) 50%,transparent 50%),linear-gradient(0deg,var(--primary) 50%,transparent 50%);
//  background-position: ;
//  background-repeat: ;
//  background-size: ;
//  border-radius: ;
//  border-radius: var(--border-radius-base);
//  margin-bottom: 30px;
//  position: relative;
export default function About() {
  const values = UseProContext();
  useEffect(() => {
    document.title = `About Us || India's Best Digital Invitation E Card Maker - Animated Invitation Maker - Digi Invite`;  
  }, []);
  // const opts = {height: '315',width: '560',playerVars: {autoplay: 1,},};
  // const onReady=(event)=> {
  //   event.target.pauseVideo();
  // }
  return (
    <StyledAbout>
      {/* <Helmet>
        <title>About Us || India's Best Digital Invitation E Card Maker, Animated Invitation Maker - Digi Invite</title>
        <meta name="description" content="One of the top online makers of digital invitations is Digiinvite.
      Create personalized invitations for Rajasthani, Punjabi, Sikh, and Hindu wedding, Engagements, birthdays, housewarmings, parties, and anniversaries in 1-2 business days.
      create stylish digital invitations such as E-Cards, PDFs, and Animated video invitations. We are one of the only company that can provide caricature invitations as soon as possible."/>
    </Helmet> */}
      <>
        <Banner name={`About Us`}/> 
      </>
      <Box sx={{ width: "100%" }}>
        <Box className="container">
          <Box className="row" sx={{mt:5,pt:5}}>
            <Box sx={{width: { xs: "100%", md: "50%" },display:'flex',flexWrap:'wrap'}}>
              <Box sx={{width: { xs: "100%", md: "50%" },p:1}}>
                <Box sx={{position:'relative', backgroundSize: 'cover',height: '436px',backgroundPosition: '50%',borderRadius:'8px', }}
                    style={{backgroundImage:`url('https://www.digiinvite.in/ceo/assets/images/about1.png')`}}
                ></Box>
                {/* <img className="m-b30" src="https://bookland.dexignzone.com/react/demo/static/media/about1.0a2c2d8d8419b0bcd559.jpg" alt=""/> */}
              </Box>
              <Box sx={{width: { xs: "100%", md: "50%" },p:1}}>
                <Box sx={{position:'relative', backgroundSize: 'cover',height: '300px',backgroundPosition: '50%',borderRadius:'8px', }}
                    style={{backgroundImage:`url('http://www.digiinvite.in/ceo/assets/images/about2.png')`}}
                ></Box>
                  <Box sx={{display:'flex',ml:{ xs: "0", md: '-35%'  },mt:3,zIndex:2,position:'relative',width:'150%'}}>
                    <Box sx={{background:'#ffad19',color:'white',width:'40%',borderRadius:'6px',p:2,m:1}}>
                      <Typography variant="h2"   sx={{color:'white',fontWeight:'600'}}><span className="counter"> <span>10</span></span><small>+</small></Typography>
                      <h6 style={{color:'white'}}>Years of Experience</h6>
                    </Box>
                    <Box sx={{width:'60%',borderRadius:'6px',pt:2,m:1}}>
                      <ul className="list-check primary">
                        <li><i className={`fa fa-check`}></i> Digital Animated E Cards </li>
                        <li><i className={`fa fa-check`}></i> Digital PDF E-Cards </li>
                        <li><i className={`fa fa-check`}></i> Web E-Card  </li>
                        <li><i className={`fa fa-check`}></i> Logo Animation  </li>
                        <li><i className={`fa fa-check`}></i> Documentary Video </li>
                        <li><i className={`fa fa-check`}></i> Explainer Animated Video </li>
                        <li><i className={`fa fa-check`}></i> Caricature Pdf E-Card </li>
                        <li><i className={`fa fa-check`}></i> Product Promotional Video </li>
                      </ul>
                    </Box>
                  </Box>
              </Box>
            </Box>
            <Box sx={{width: { xs: "100%", md: "50%" },p:{ xs:2,md:5}}}> 
            <Typography variant="h4"   sx={{ fontWeight:'600',color:'#ffad19'}}>
            About The Company 
            </Typography>
            <Typography     sx={{ fontWeight:'400',color:'gray',}}>
            DigiInvite is a digital invitation card maker from India. which was started by Managing Director Diljit Singh Dhillon. 
            This company is a part of iSmart Media (a digital marketing company) which was started in 2012, and after the success of iSmart Media, 
            the DigiInvite Company was launched, which helps all kinds of invitations reach you and your relatives. has a great impact and saves you time and money too.
            </Typography>
            <Link to='/contact'>
            <Button sx={{bgcolor:'#ffad19',color:'white',mt:5}}>Contact Us</Button>
            </Link>
            </Box>
          </Box>
        </Box>
      {/* </Box> */}

      <Box sx={{ width: "100%",bgcolor:'#f5f5f5' }}>
        <Box className="container">
          <Box  sx={{ p:2 ,display:'flex',flexDirection:'column',alignItems:'center'}}>
            <Typography variant="h4" sx={{ fontWeight:'600',color:'#ffad19'}}>
              Our Mission
            </Typography>
            <Typography     sx={{ fontWeight:'400',color:'gray',width:{ xs:"100%",md:'70%'},textAlign:'center'}}>
            One of the top online makers of digital invitations is DigiInvite. Create personalised invites for weddings, engagements, birthdays, and
            anniversaries in 1-2 business days. With only one click, video invitations may be sent via WhatsApp, Facebook, or other social media platforms.
            Within 24 working hours, create stylish digital invitation E-Cards, PDFs, and animated video invitations
            </Typography>
          </Box>
          <Box className="row" sx={{ pt:3,pb:3, }}>
            <DivHover sx={{width: { xs: "100%", md: "30%" },mt:3}}>
              <i className={`fa fa-check`}style={{fontSize:"xxx-large"}}></i>
              <h4 style={{color:'#ffad19'}}>Why Choose DigiInvite</h4>
              <p style={{color:'gray'}}>DigiInvite  reduce costs and time.<br/> It is a place where you can get fantastic animated video or E-Cards to celebrate the wonderful moments of your life.</p>
            </DivHover>
            <DivHover sx={{width: { xs: "100%", md: "30%" },mt:3,}} >
              <i className={`fa fa-check`} style={{fontSize:"xxx-large"}}></i>
              <h4 style={{color:'#ffad19'}}>A Wide Selection</h4>
              <p style={{color:'gray'}}>We have a wide range of e-cards and animated videos in many different categories.</p>
            </DivHover>
            <DivHover sx={{width: { xs: "100%", md: "30%" },mt:3,}}>
              <i className={`fa fa-check`}style={{fontSize:"xxx-large"}}></i>
              <h4 style={{color:'#ffad19'}}>Our Customer Experience</h4>
              <p style={{color:'gray'}}>We have delivered many projects to our clients, and they are very satisfied with our services.</p>
            </DivHover>
          </Box>
        </Box>
      </Box>
      {/* <Box sx={{ width: "100%" }}> */}
        <Box className="container" >
          <Box sx={{pt:5,mt:5}}>
            <Typography variant="h4" sx={{ fontWeight:'600',color:'#ffad19'}}>
              Testimonials
            </Typography>
            <Typography  sx={{ fontWeight:'400',color:'gray', display:{ xs: 'none', md: 'block' }}}>
            We have delivered many projects to our clients, and they are very satisfied with our services.
            </Typography>
          </Box>
          <Box sx={{pt:3}}>
            <Review  reviews={values.reviews} />
          </Box>
        {/* </Box> */}
      {/* </Box> */}
      
      {/* <Box sx={{ width: "100%" }}> */}
        {/* <Box className="container"> */}
          <Box sx={{ p: "50px 5%" }}>
            <Box sx={{ display: { xs: "block", sm: "flex", md: "flex" }, flexWrap: "wrap",alignItems: "center", justifyContent: "space-around", }}>
              <Box sx={{display: "flex",flexDirection: "column",alignItems: "center",mb:2,mt:5}}>
                <StyledIcon sx={{p:4}}>
                  <SentimentSatisfiedAltIcon sx={{  }} />
                </StyledIcon>
                <Typography  variant="h2"  sx={{ fontWeight: 600, fontSize: "2.375rem", color: "#ffad19" }}>
                  <CountUp  duration={1} end={6000} enableScrollSpy scrollSpyOnce />+
                </Typography>
                <Typography    sx={{   fontSize: "1.4rem", color: "#7e7d7d" }}>
                Happy Customers 
                </Typography>
              </Box>
              <Box sx={{display: "flex",flexDirection: "column",alignItems: "center",mb:2,mt:5}}>
                <StyledIcon sx={{p:4}}>
                  <DownloadForOfflineIcon    />
                </StyledIcon>
                <Typography   variant="h2"  sx={{ fontWeight: 600, fontSize: "2.375rem", color: "#ffad19" }}>
                  <CountUp duration={2} end={250} enableScrollSpy scrollSpyOnce />+
                </Typography>
                <Typography sx={{   fontSize: "1.4rem", color: "#7e7d7d" }} >
                Available Templates 
                </Typography>
              </Box>
              <Box sx={{display: "flex",flexDirection: "column",alignItems: "center",mb:2,mt:5}}>
                <StyledIcon sx={{p:4}}>
                  < VideoLibraryIcon /> 
                </StyledIcon>
                <Typography  variant="h2"   sx={{ fontWeight: 600, fontSize: "2.375rem", color: "#ffad19" }}>
                  <CountUp end={100} enableScrollSpy scrollSpyOnce />+
                </Typography>
                <Typography  sx={{   fontSize: "1.4rem", color: "#7e7d7d" }}>
                 Animated Video
                </Typography>
              </Box>
              <Box sx={{display: "flex",flexDirection: "column",alignItems: "center",mb:2,mt:5}}>
                <StyledIcon sx={{p:4}}>
                  <PeopleAltIcon   />
                </StyledIcon>
                <Typography  variant="h2" sx={{ fontWeight: 600, fontSize: "2.375rem", color: "#ffad19" }}>
                  <CountUp end={100} enableScrollSpy scrollSpyOnce />+
                </Typography>
                <Typography  sx={{  fontSize: "1.4rem", color: "#7e7d7d" }}>
                Resellers
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
      {/* <Box sx={{ width: "100%" }}> */}
        {/* <Box className="container">
          <Box className="row">
            <Box sx={{  pr: { xs: 0, md: 7 }, pl: { xs: 0, md: 0 }, width: { xs: "100%", md: "60%" },}}> 
              <Box sx={{ width: "100%" }}></Box>
            </Box>
            <Box sx={{ width: { xs: "100%", md: "40%" } }}>
              <h4>About Us</h4> <p> </p>
            </Box>
          </Box>
        </Box> */}
      {/* </Box> */}
    </StyledAbout>
  );
}
