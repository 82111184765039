import React,{useState,useEffect} from 'react';
import Banner from "./homes/banner";
import Box from "@mui/material/Box";
import { UseUserContext } from '../context/userContext '; 
import Typography from "@mui/material/Typography";
import { Link} from "react-router-dom";
import Modal from '@mui/material/Modal';
import axios from 'axios';

const Account = () => {
    const users=UseUserContext(); 
//  const initialState={name:'ok',}
    const [user, setUser] = useState({});
    useEffect(() => {
        setUser(...users.user);
    }, [users]);
    // user&&console.log(user);

    useEffect(() => {
        if (localStorage.getItem("token-info") ==null) {
            window.history.pushState({}, "", "/Sign" );
            const navEvent = new PopStateEvent('popstate');
            window.dispatchEvent(navEvent);
        } 
    });

    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const [after, setAfter] = useState(null);
    const [userName, setUserName] = useState(null);
    // const [newPassword, setNewPassword] = useState(null);
    const [address, setAddress] = useState(null);
    const [name, setName] = useState(null);
    const [number, setNumber] = useState(null);
    const [email, setEmail] = useState(null);
    const [country, setCountry] = useState(null);
//   const [message, setMessage] = useState(null);

    // const [state, setState] = useState(false);
    useEffect(() => {
        user&&setUserName(user.userName);
        user&&setName(user.name);
        user&&setNumber(user.mobile);
        user&&setCountry(user.country);
        user&&setEmail(user.email);
        user&&setAddress(user.address);
    }, [user]);

  function details(typ,value) { 
    switch (typ) {
        case "Name":
            setName(value);
            break;
        case "Address":
            setAddress(value);
            break; 
        case "Number":
            setNumber(value);
            break;
        case "Email":
            setEmail(value);
            break;
        case "Country":
            setCountry(value);
            break; 
        default:
            break;
    }    
  }

  
  const editHandel=(e,typ)=>{
    e.preventDefault();
    const login=localStorage.getItem("login"); 
    const auth  = 'sarwara774h6g2v2jig5d55hf77ks';
    const payload = {keys: auth ,typ:typ, login:login,userName:userName,
        address:address,name:name,number:number,country:country,email:email };
    // console.log(payload);
    apiHandel(payload,typ);
}

const apiHandel=async (payload,typ)=>{
    try{ 
        const res = await axios.post('https://www.digiinvite.in/api/user/index.php', payload);
        const dates =await res.data;  
        // const details =await dates.details;
        const result =await dates.result;
        setAfter(dates.errors)
        result ? window.location.href = '/profile':console.log(result);
        // result&&window.history.pushState({}, "", "/profile" );
        // const navEvent = new PopStateEvent('popstate');
        // result&&window.dispatchEvent(navEvent) ;
        // console.log(dates);
      } catch(error){
        console.log('API_ERRORS');
      }
}
        
    return (
    <>
    <Banner name={`My Profile`}/>
    <Box sx={{ width: "100%" }}>
        <Box className="container">
            {/* <Box className="row" sx={{mt:5,pt:5}}>
            <Box sx={{width: { xs: "100%", md: "50%" },display:'flex',flexWrap:'wrap'}}>1</Box>
            <Box sx={{width: { xs: "100%", md: "50%" },display:'flex',flexWrap:'wrap'}}>2</Box>
            </Box> */}
            <Box className="row " sx={{my:5,py:5}}>
            <Box sx={{width:{ xs: "90%",sm:"50%", md: "40%"},p:{ xs: 2, md:4 }}}>
             <div className="  ">
                <img className=" " src="https://www.digiinvite.in/assets/images/reviews1.png" alt="" 
                style={{width:" 100%", height: "auto"}} />
                <Typography component={`h3`} variant={`h4`} 
                sx={{textAlign:'center',color:'#ffad19',fontWeight:600}}  
                >{user&&user.name}</Typography>  
              </div>
            </Box>
            <Box sx={{width:{ xs: "90%",sm:"50%", md: "60%" },p:{ xs: 1, md:5 }}}>
             <div className="   ">
                <Typography component={`h4`} variant={`h5`} sx={{textAlign:'center',color:'#ffad19',fontWeight:500,
                    width:{ xs: "100%",  md: "50%" },my:{ xs: 1, md:4 }}} >Details</Typography>  
                <Detail name={`User Name`} value={`@${user&&user.userName}`} />
                <Detail name={`Mobile`} value={`${user&&user.country} ${user&&user.mobile}`} />
                <Detail name={`Email`} value={user&&user.email} />
                {user&&user.address?<Detail name={`address`} value={user&&user.address}/>:<></>}
                <Box className='row' sx={{width:{ xs: "80%",sm:"80%",  md: "50%" }}}>
                    <Box sx={{width:{ xs: "50%",sm:"50%", md: "50%" },p:{ xs: 1, md:2 }}}>
                        <button type="button" className="btn btn-primary " onClick={handleOpen} >Edit</button> 
                    </Box>
                    <Box sx={{width:{ xs: "50%",sm:"50%", md: "50%" },p:{ xs: 1, md:2 }}}>
                        <Link variant="button" to={`/new-password`} type="button" className="btn btn-primary " >Change Password</Link> 
                    </Box>
                </Box>
                <Modal  open={open}   onClose={handleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description">
                    <Box sx={{position: 'absolute',top: '50%',left: '50%',transform: 'translate(-50%, -50%)',
                        width: { xs: "90%",sm:"75%", md: "50%" },bgcolor: 'background.paper',border: '2px solid #00000080',boxShadow: 24,p: 4,}}>
                        {after&&after.map((er,i)=><p key={i} style={{color:'red'}}>{er}</p>)}
                        <Typography component={`h3`} variant={`h4`} sx={{ color:'#ffad19',fontWeight:600, my:2}} >Edit Details</Typography> 
                        <form  className='form-horizontal' onSubmit={(e)=>editHandel(e,5)}>
                        <div className="form-floating mb-4">
                            <label htmlFor="User">Your Name</label>
                            <input type="text" className="form-control" id="User" placeholder="your name" required
                            onBlur={(e)=>details('Name',e.target.value)} defaultValue={name}/>
                        </div>

                        {/* <div className="form-floating mb-4">
                            <label htmlFor="email">Email </label>
                            <input type="email" className="form-control" id="email" placeholder="name@example.com" 
                            onBlur={(e)=>details('Email',e.target.value)}/>
                        </div> */}

                        <div className="form-floating mb-4">
                            <label htmlFor="mobile">Mobile </label>
                            <input type="text" className="form-control" id="Country" placeholder="Country Code" 
                            onBlur={(e)=>details('Country',e.target.value)} defaultValue={country} style={{width:'15%'}}/>
                            <input type="number" className="form-control" id="mobile" placeholder="your mobile" maxLength={10} required
                            onBlur={(e)=>details('Number',e.target.value)} defaultValue={number} style={{width:'60%'}}/>
                        </div> 
                        <div className="form-floating mb-4">
                            <label htmlFor="Address">Address </label>
                            <input type="text" className="form-control" id="Address" placeholder="Your Address"  required
                            onBlur={(e)=>details('Address',e.target.value)} defaultValue={address} />
                        </div> 
                         <Box sx={{textAlign:'center'}}>
                            <button type="submit" className="btn btn-primary   mb-4" >Edit Detail</button>
                         </Box>
                        </form>
                    </Box>
                </Modal>
              </div>   
            </Box>

        </Box>
        </Box>
    </Box>
         
    </>
    );
}

 
const Detail = ({name,value}) => {
    return (
        <>
        <Box component='ul' className=" row"sx={{width: "auto",my:1}}>
            <Box component='li' sx={{width:{ xs: "35%",sm:"40%", md: "30%" },lineHeight:2}} >
                 {name}
            </Box> 
            <Box component='li' sx={{width:{ xs: "65%",sm:"60%", md: "70%" },fontWeight:600}}> 
                 {value}
            </Box>
        </Box>
            
        </>
    );
}
 


export default Account;
