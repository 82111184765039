import React,{useState} from "react";
import Box from '@mui/material/Box';
import axios from 'axios';

const Newsletter = () => {

  
    const [number, setNumber] = useState(null);
    // console.log(number);
        
    const handleSubmit=async (e)=> {
      e.preventDefault();
      try{ 
          const auth  = 'ks4h6g2v2j2l2big5h7b5jdc45vd5hcy5hf7mob77di';
          const payload = {key: auth ,title: 'title' ,message: 'message',number:number, };
          const res = await axios.post('https://www.digiinvite.in/api/query/index.php', payload);
          const dates =await res.data;
          // const payment =await dates.payment; 
          console.log(dates);   
          document.getElementById('contact_form').reset();  
        } catch(error){
          console.log('API_ERROR');
        }
    }
  return (
    < >
    <div className="newsc text-center col-xs-12 col-md-6">
      <div className="newsltr">
        <div className="newsldesign">
          <Box sx={{p:'0 16px',}} className=" newsl ">
            <h4 >Please leave your WhatsApp number if you have any questions.</h4>
          </Box>
          <div className="form-vertical">
            <form id="contact_form" className="contact-form"
              onSubmit={(e) => {handleSubmit(e)}}
              acceptCharset="UTF-8"
            >
              {/* <input type="hidden" name="form_type" defaultValue="customer" />
              <input type="hidden" name="utf8" defaultValue="✓" />
              <input type="hidden" name="contact[tags]" defaultValue="newsletter" /> */}
              <div className="newsb input-group">
                <input
                  onChange={(e)=>setNumber(e.target.value)}
                  type="number"
                  defaultValue=""
                  placeholder="WhatsApp Number" 
                  id="Number"
                  className="input"
                  autoCorrect="off"
                  autoCapitalize="off"
                />
                <div className="input-group-btn">
                  <button type="submit" className="btn" name="commit">
                    Send
                  </button>
                </div>
              </div>
            </form>
          </div>

          <div className="header_social">
            <ul className="inline-list social-icons">
              <span className="c-info h3">Follow Us</span>
              <li>
                <a className="icon-fallback-text youtube hexagon" target="_blank"
                  href="https://www.youtube.com/@digiinviteinvitationecardm1607/featured" title="youtube" rel="noreferrer"  >
                  <i className="fa fa-youtube-play" style={{fontSize:'20px',}}></i>
                </a>
              </li>
              <li>
                <a  className="icon-fallback-text fb hexagon" target="_blank" title="Facebook" rel="noreferrer"
                  href="https://www.facebook.com/profile.php?id=100085685974325" >
                  <i className="fa fa-facebook" style={{ fontSize:'20px'}}></i>
                </a>
              </li>
              <li>
                <a className="icon-fallback-text ins" title="Instagram" target="_blank"
                href="https://www.instagram.com/digiinvite.invitation/" rel="noreferrer" 
                >
                  <i className="fa fa-instagram" style={{fontSize:'20px'}}></i>
                </a>
              </li>
              <li>
                <a  className="icon-fallback-text twitt hexagon" target="_blank"
                  href="https://twitter.com/DigiInvite"  title="Twitter" rel="noreferrer" >
                  <i className="fa fa-twitter" style={{ fontSize:'20px'}}></i>
                </a>
              </li>
              <li>
                <a  className="icon-fallback-text pin hexagon" target="_blank"
                  href="https://in.pinterest.com/digiinvitecard/" title="Pinterest" rel="noreferrer" >
                  <i className="fa fa-pinterest" style={{ fontSize:'20px'}}></i>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    </>
  );
};

export default Newsletter;
