/* eslint-disable react-hooks/exhaustive-deps */
import  React, { createContext,useContext,useEffect,useReducer } from 'react';
import axios from 'axios';
import reducer from '../reducer/userReducer';
// import { type } from '@testing-library/user-event/dist/type';useState
// import { ConnectWithoutContactSharp } from '@mui/icons-material';
const UserContext= createContext();


const initial={
  isLoading:true,
  isError:false,
  user:[],
  details:[],
  errors:[],
  result:false,
};
const UserProvider = ({children,keys}) => {

  const API = `https://www.digiinvite.in/api/user/`; 
  // eslint-disable-next-line react-hooks/exhaustive-deps
  let tokenInfo = '';
  if (localStorage.getItem("token-info") == null) {
    tokenInfo = false;
  }else{tokenInfo = localStorage.getItem('token-info');} 
 
  const payload = {keys: keys ,typ: 10 ,token: tokenInfo, };
  // console.log(payload);
  const [state, dispatch] = useReducer(reducer, initial)
  // const [value, setValue] = useState('Karmveer');

  const getData= async (url,payload)=>{
    dispatch({type:"SET_LOADING"});
    try{
      const res = await axios.post(url,payload);
    const dates =await res.data;
    const result =await dates.result;
    if(result===false){
      localStorage.removeItem('token-info');
    }
    // console.log(dates);
    dispatch({type:"MY_API_DATA",payload:dates});
    // setValue(data);

    } catch(error){
      dispatch({type:"API_ERROR"});
    }
    
  };
 

  // console.log(state);
  
  
  useEffect(() => {
    getData(API,payload);
  },[API]);

    // console.log(value);
  
  return (<UserContext.Provider value={{ ...state,dispatch}}>{children}</UserContext.Provider>);
};

const UseUserContext = () => {
  return useContext(UserContext);
};


export {UserProvider, UserContext, UseUserContext};