import React, { useEffect,useState } from 'react';
import { UseCartContext } from '../context/CartContext';
import { UseProContext } from '../context/productContext';
import { Link } from "react-router-dom";
import FormatPrice from "./FormatPrice";
// import CartProductList from './CartProductList';useContext,
// import CartCheckoutRow from './CartCheckoutRow';
// import CartTotalRow from './CartTotalRow';
// import CartContext from '../../context/CartContext';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import CloseIcon from '@mui/icons-material/Close';
import { styled} from '@mui/material/styles';

const CartStyle = styled(Box)(({ theme }) => ({
  backgroundColor: 'white', 
    "& .MuiTypography-root":{ fontFamily: 'Work Sans',
    // '&:hover':{color:`#ffad19`}MuiButton-text
    '&:hover':{color:`#ffad19`, }
  },
    "& .MuiButton-text":{ fontFamily: 'Work Sans',color:`#fff`,backgroundColor: '#ffad19',
    '&:hover':{color:`#ffad19`,backgroundColor: '#fff',}
  },  
    // "& .MuiButton-root":backgroundColor: '#000'{ fontFamily: 'Work Sans', backgroundColor: '#ffad19', }, 
}));

const Cart = ({closeCart}) => {
  const handleCloseCart=()=>{
    closeCart(null);
  }

  const values=UseProContext();
  const product =values.products;

  const {carts,setCarts}=UseCartContext();
  // const cartCount =carts.count;
  const cartPros =carts.cart;
  // console.log(carts);


  const [cartPro, setCartPro] = useState(cartPros);
  useEffect(() => {
   setCartPro(cartPros);
  }, [cartPros]);
  
  const [totalPrice, setTotalPrice] = useState(0);
  useEffect(() => {
    setTotalPrice(null);
    // eslint-disable-next-line array-callback-return
    Object.keys(cartPro).map((obj) => {
      // cartPro[obj].count*
      const options=cartPro[obj]
      const totals=options.price+options.logo+options.Language+options.Version+options.Caricature+options.Extra;
      // const totals=cartPro[obj].price;
      setTotalPrice(oldArray=>oldArray+totals);
    });
  }, [cartPro]);

  // const [gstPrice, setGstPrice] = useState(0);
  // useEffect(() => {
  //     setGstPrice(totalPrice+totalPrice*18/100);
  // }, [totalPrice]);

  // function handleAddProduct(productId) {
    // localStorage.removeItem(key);
    // const AddProId=[];
    // AddProId.push(productId);
    // const saveProId =localStorage.getItem('pro_id');
    // AddProId.push(saveProId);
    // localStorage.setItem('pro_id', AddProId);
    // setCarts({type:"MY_CART_DATA",payload:localStorage.pro_id});
  // }
  function deleteProduct(id) {
      // localStorage.setItem('pro_id', '');
      // cartPro.forEach((pro) => {
      //   if(pro.pro_id!==id){
      //     for (let i = 0; i < pro.count; i++) {
      //       let pro_ids = localStorage.getItem('pro_id');
      //       localStorage.setItem('pro_id', pro.pro_id+','+pro_ids);
      //     }
      //   }
      // });
      // let newCart = localStorage.getItem('pro_id');
      // if(!newCart){newCart=','}
      // setCarts({type:"MY_CART_DATA",payload:newCart,pro:product});
      const savePro =localStorage.getItem('pro_form');
      const Pro=JSON.parse(savePro);
      const toDelete = new Set([id, ' ']);
      const newArray = Pro.filter(obj => !toDelete.has(obj.id));
      // console.log(newArray);
      const option=JSON.stringify(newArray);
      localStorage.setItem('pro_form', option);
         
      setCarts({type:"ADD_CART_DATA",pro:product,data:newArray});

      // console.log(Pro); 
      // const savePro =localStorage.getItem('pro_form');
      // const Pro=JSON.parse(savePro);

  }

      // const upResults = cartPro.filter(obj => obj.pro_id!==id);
      // setCartPro(upResults);
  // const imgOffers={color:'green',fontWeight: 700, position: 'absolute',width: '100%',textAlign: 'center',};
  // transform: 'translate(-50%)'
  // if (cartCount.length  > 0) {}

    return (
      <CartStyle >
      {cartPro.length  > 0 ?
      <Box sx={{p:0,}} id="slidedown-cart" >
        {/* <Typography sx={{fontWeight: 700,fontSize:'2rem',  }} >
          recently added items <IconButton onClick={handleCloseCart} sx={{textAlign:'end'}}><CloseIcon /></IconButton>
        </Typography> */}
        <Box component={'ul'}>

        {Object.keys(cartPro).map((obj, i) =>
          <Box sx={{m:0,display:'flex'}} key={i} component={'li'}>
            <Box sx={{p:1,}}>
            <Link to={`/product/${cartPro[obj].code}`}>
            <figure>
              {/* <span  style={imgOffers} > {cartPro[obj].offer}</span> */}
              <img width='60px' src={cartPro[obj].img[0]&&cartPro[obj].img[0]?`${cartPro[obj].img[0].url}`
              :`http://ceo.digiinvite.in/assets/images/pro.png`} alt={cartPro[obj].pro_title} />
            </figure>
            </Link>
            </Box>
            <Box sx={{p:1,  }} className="product-details">
            <Link to={`/product/${cartPro[obj].code}`}>
              <Typography sx={{ fontWeight: 600,fontSize:'0.8rem',  }} className="product-name" >
                {cartPro[obj].pro_title}
              </Typography>
            </Link>
              <Box sx={{ fontSize:'0.8rem',display:'flex', flexWrap:'wrap' }} >
                
                 <span style={{width:'75%',}}> 
                  {cartPro[obj].code} <br/>
                  {/* <Typography><b>Card Price: </b> <FormatPrice price={cartPro[obj].price} /></Typography>
                  <Typography><b>Total Price: </b> </Typography> */}
                  <FormatPrice price={cartPro[obj].price+cartPro[obj].logo+cartPro[obj].Language+cartPro[obj].Version+cartPro[obj].Caricature+cartPro[obj].Extra} />
                  {/* <FormatPrice price={cartPro[obj].price} /> */}
                 </span>  
                 <IconButton onClick={()=>deleteProduct(cartPro[obj].pro_id)} sx={{width:'25%'}}><DeleteIcon /></IconButton>
              </Box>
              {/* <Typography sx={{ fontSize:'1rem',textAlign:'end'  }} >
              <IconButton onClick={()=>deleteProduct(cartPro[obj].pro_id)}><DeleteIcon /></IconButton> 
              </Typography> */}
            </Box> 
            {/* <IconButton onClick={()=>deleteProduct(cartPro[obj].pro_id)}><DeleteIcon /></IconButton> */}

          </Box>
          )}
          </Box>
        
        <Box sx={{p:"0 16px",display:'flex',justifyContent:'space-between'}}>
          <Box sx={{fontWeight: 600,fontSize:'1rem',  }} >
          Cart total:  
          </Box>
          <Box sx={{fontWeight: 600,fontSize:'1rem',  }} >
          <FormatPrice price={totalPrice}  />
          </Box>
          {/* <Typography sx={{fontWeight: 700,fontSize:'1.5rem',  }} >
            Total price With 18% Gst = {gstPrice}
          </Typography> */}
        </Box>
        <Box sx={{p:"0 16px",display:'flex',justifyContent:'space-evenly'}} className="actions">
          <Link to='checkout' preventScrollReset={true}><Button onClick={handleCloseCart} className="btn">Checkout</Button></Link>
          <Link to='carts' preventScrollReset={false}><Button onClick={handleCloseCart} className="btn">View Cart</Button></Link>
        </Box>
      </Box>
      :<Box sx={{p:1}}className="no-items">
        <Typography sx={{ fontSize:'0.8rem',  }} className="text-continue">
        Your cart is currently empty! <IconButton onClick={handleCloseCart} sx={{textAlign:'end'}}><CloseIcon /></IconButton>
        </Typography>
      </Box> }
      </CartStyle>
    );
  
}

export default Cart;
