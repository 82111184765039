import React from "react";
import { Box } from "@mui/material";
import Slider from "react-slick"; 
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';

// import { Link } from "react-router-dom";

// const img1="//cdn.shopify.com/s/files/1/0531/1604/3454/collections/1_1024x1024.jpg?v=1611749247";
const ShowCate = ({category,isLoading}) => {
    var settings = { 
        speed: 500,
        slidesToShow: 5,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 2000,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 4,
              slidesToScroll: 1,
              infinite: true,
              // dots: true
            }
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 1
            }
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 1
            }
          } 
        ]
    }
  return (
    <Box> 
      <h1 className="heading">
        <span>Categories</span>
      </h1>
      {isLoading? 
      <Box sx={{display:'flex',flexWrap:'wrap', justifyContent:'space-between'}}>
        <StackCate dis='block'/>
        <StackCate dis='block'/>
        <StackCate dis='none'/>
        <StackCate dis='none' />
      </Box>
      : 
      <Slider {...settings}>
        {category.map((cate,i)=>
        i%2===0 ? '':<Box key={cate.cate_id} >  
          {i>1?<> <Cate  name={`${category[i-1].titles}`} url={`${category[i-1].cate_link}/${category[i-1].sub_tag}`} img={category[i-1].img} /> </>:<>
          <Cate  name={`${category[0].titles}`} url={`${category[0].cate_link}/${category[0].sub_tag}`} img={category[0].img} /></>}
          <Cate  name={cate.titles} url={`${cate.cate_link}/${cate.sub_tag}`} img={cate.img} />
        </Box>
        )}
      </Slider>}


        {/* <Cate name='Fashion' img={img[0]} /> */}
         {/* <Box  className="sliders class"></Box> && i>1*/}

      {/* <Slider {...settings}>
        <Cate name='Fashion' img={img1} />  
      </Slider> */}

    </Box>
  );
}; 
const Cate = ({name,img,url}) => {
  // if(img===''){
  //   img=`http://ceo.digiinvite.in/assets/images/category.png`;
  //    img=`http://ceo.digiinvite.in/assets/images/logo.png`;
  // }
  const imgUrl=`${img?`https://www.digiinvite.in/ceo/images/${img}`:`https://www.digiinvite.in/ceo/assets/images/category.png`}`;
    return ( 
    <Box className="twopro" sx={{p:1}} >
        <Box className="product-cate wbproductdes">
        <Box className="collection-grid-item" sx={{textAlign:'center'}}>
            <a  href={`/products/${url}`} className="collection-grid-item__link">
                <img className=""alt={name}  src={imgUrl} width='100%' />
                <h5> <span>{name} </span></h5> 
            </a>
        </Box>
        </Box>
    </Box> 
    );
}

 
const StackCate = ({dis}) => {
  return (
    <Box sx={{width:{xs:'48%',md:'23%'},display:{xs:dis,md:'block'}}}>
      <Stack spacing={1} sx={{p:"0 16px",width:'100%', }}> 
        <Skeleton variant="rectangular" width={'100%'} sx={{height:{xs: 100, md:125 },borderRadius:'8px'}} /> 
      </Stack>
      <Stack spacing={1} sx={{p:"0 16px",width:'100%',pt:3,}}> 
        <Skeleton variant="rectangular" width={'100%'} sx={{height:{xs: 100, md:125 },borderRadius:'8px'}} /> 
      </Stack>
    </Box>
  );
}
 

 


export default ShowCate;
